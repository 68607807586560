<template>
  <div class="EsignConsent">
    <div class="document-loading">
      <slot
        name="loading"
        v-if="isLoading"
      >
        <!-- <p v-text="$t('EsignConsent.loading')"></p> -->
        <UiLoading :label="$t('EsignConsent.loading')" />
      </slot>
    </div>

    <slot
      name="notfound"
      v-if="isError"
    >
      <p v-text="$t('EsignConsent.notFound')"></p>
    </slot>

    <div
      v-if="document"
      class="document-html"
    >
      <slot
        name="html"
        :document="document"
      >
        <div class="object-loading">
          <UiLoading :label="$t('EsignConsent.loadingPreview')" />
        </div>

        <object
          width="100%"
          height="600"
          type="application/pdf"
          :data="document.pdfUrl"
        >
          <!-- Fallback del objeto.  Mostrar el HTML -->
          <div class="pdf-container">
            <div
              class="document-html pdf-html"
              v-html="document.source.html"
            ></div>
          </div>

          <!-- y un boton para descargar -->
          <div class="dl-btn-container">
            <a
              class="pdf-link ui-button --main"
              target="_system"
              :href="document.pdfUrl"
            >Descargar</a>
          </div>
        </object>
      </slot>
    </div>

    <div
      v-if="document && !ignoreSignatures"
      class="document-signatures"
    >
      <slot
        name="signatures"
        :document="document"
        :sign="sign"
      >
        <div class="document-signature-list">
          <div
            v-for="(signature, i) in sanitizedSignatures"
            :key="signature.id"
            class="document-signature"
            :class="{'--mine': signature._isMine, '--signed': signature._isSigned, '--locked': !signature._isWritable}"
          >
            <PersonItem
              class="signature-person"
              :person="signature.person"
              :secondary="signature._isSigned ? $t('EsignConsent.signed') + ' ' + $ts(signature.dateSigned) : $t('EsignConsent.pending')"
            >
              <template
                #right
                v-if="signature._isSigned"
              >
                <UiIcon
                  class="signed-icon"
                  value="mdi:check-decagram"
                />
              </template>
            </PersonItem>

            <button
              v-if="signature._isWritable"
              type="button"
              class="sign-button ui-native --main"
              @click="sign(i)"
            >{{ $t('EsignConsent.sign') }}</button>

          </div>

        </div>
      </slot>
    </div>

  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi.js';

import PersonItem from '@/modules/core/components/Person/ListItem.vue';
import { UiLoading, UiIcon } from '@/modules/ui/components';
import HtmlToPdf from '../HtmlToPdf/HtmlToPdf.vue';

export default {
  name: 'EsignConsent',
  mixins: [useI18n, useApi],
  components: { UiLoading, UiIcon, PersonItem, HtmlToPdf },

  props: {
    documentId: {
      type: String,
      required: false,
      default: null,
    },

    personId: {
      type: String,
      required: false,
      default: null,
    },

    documentName: {
      type: String,
      required: false,
      default: null,
    },

    /**
     * Si se recibe un source, se asume que se debe crear (o intentar crear)
     * el documento
     */
    source: {
      required: false,
      default: null,
    },

    /*
    When TRUE, only the author's signature is required
    */
    authorSignatureOnly: {
      required: false,
      default: false,
    },

    /*
    When TRUE, no signatures are required
    */
    ignoreSignatures: {
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: true,
      isError: false,
      document: null,

      timeout: null,
    };
  },

  computed: {
    userId() {
      return this.$httpClient ? this.$httpClient.payload.id : null; // current authenticated user
    },

    sanitizedSignatures() {
      if (!this.document || !this.document.signatures) {
        return [];
      }

      return this.document.signatures.map((s) => ({
        ...s,
        _isMine: s.personId == this.userId,
        _isSigned: s.dateSigned > 0,
        _isWritable: s.personId == this.userId && !s.dateSigned,
      }));
    },

    watchableData() {
      return [this.documentId, this.documentName, this.personId, this.source];
    },
  },

  watch: {
    watchableData: {
      immediate: true,
      deep: true,
      handler() {
        this.debounceLoad();
      },
    },
  },

  methods: {
    debounceLoad() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        this.isError = false;
        this.isLoading = true;

        // await new Promise((r) => setTimeout(r, 2000)); // sleep 2000

        try {
          if (this.source) {
            await this.createDocument();
          } else {
            await this.fetchDocument();
          }

          this.$emit('fetched', this.document);

          if (this.ignoreSignatures) {
            this.$emit('signed', this.document);
          }

        } catch (e) {
          this.isError = true;
        }

        this.isLoading = false;
      }, 180);
    },

    async fetchDocument() {
      let documentUrl = null;
      if (this.documentId) {
        documentUrl = `/1/esign/documents/${this.documentId}`;
      } else if (this.personId && this.documentName) {
        documentUrl = `/v3/esign/people/${this.personId}/documents/${this.documentName}`;
      }

      if (!documentUrl) {
        throw 'Cannot fetch document.  No documentId,  or personId/documentName identifier';
      }

      this.document = await this.$httpClient.get(documentUrl);
    },

    async createDocument() {
      let documentUrl = null;
      if (this.documentId) {
        documentUrl = `/1/esign/documents/${this.documentId}`;
      } else if (this.personId && this.documentName) {
        documentUrl = `/v3/esign/people/${this.personId}/documents/${this.documentName}`;
      }

      if (!documentUrl) {
        throw 'Cannot create document.  No documentId,  or personId/documentName identifier';
      }

      this.document = await this.$httpClient.put(documentUrl, {
        source: this.source,
        metadata: {
          authorSignatureOnly: this.authorSignatureOnly,
          ignoreSignatures: this.ignoreSignatures,
        },
      });
    },

    async sign(signatureIndex) {
      if (!confirm(this.$t('EsignConsent.confirm'))) {
        return;
      }

      let response = await this.$httpClient.post(
        `/1/esign/documents/${this.document.id}/signatures`
      );

      if (typeof this.document.signatures[signatureIndex] != 'undefined') {
        this.document.signatures[signatureIndex].dateSigned =
          response.dateSigned;
      }

      this.$emit('signed', this.document);
    },
  },

  i18n: {
    en: {
      'EsignConsent.loading': '... loading ...',
      'EsignConsent.loadingPreview': '... preview ...',
      'EsignConsent.notFound': 'Document not found',
      'EsignConsent.pending': 'Signature pending',
      'EsignConsent.confirm': 'Sign the document ?',
      'EsignConsent.sign': 'Click here to sign',
      'EsignConsent.signed': 'Signed',
    },

    es: {
      'EsignConsent.loading': '... cargando ...',
      'EsignConsent.loadingPreview': '... generando ...',
      'EsignConsent.notFound': 'Documento no encontrado',
      'EsignConsent.pending': 'Pendiente de firma',
      'EsignConsent.confirm': 'Confirma que desea firmar el documento ?',
      'EsignConsent.sign': 'Click para firmar',
      'EsignConsent.signed': 'Firmado',
    },

    de: {
      'EsignConsent.loading': '... Laden ...',
      'EsignConsent.loadingPreview': '... erzeugen ...',
      'EsignConsent.notFound': 'Dokument nicht gefunden',
      'EsignConsent.pending': 'Unterschrift ausstehend',
      'EsignConsent.confirm':
        'Bestätigen Sie, dass Sie das Dokument unterschreiben möchten?',
      'EsignConsent.sign': 'Zum Unterschreiben klicken',
      'EsignConsent.signed': 'Unterschrieben',
    },
  },
};
</script>

<style lang="scss">
.EsignConsent {
  .dl-btn-container {
    margin: var(--ui-breathe);
    text-align: center;
  }

  .pdf-container {
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.07);
  }

  .pdf-html {
    margin: auto;
    max-width: 800px;
    font-family: 'Times New Roman', Times, serif !important;
    padding: 36px 36px;
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px #00000042;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'Times New Roman', Times, serif !important;
      font-weight: bold;
    }
  }

  .esign-consent-toolbar {
    margin: var(--ui-breathe) 0;
  }

  .document-html {
    position: relative;

    object {
      position: relative;
    }
  }

  .object-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>